.text-premium {
    color: #E52f12;
}

.text-plus {
    color: #fecf00;
}

.text-tecdiesel {
    color: #01a00e;
}

.text-purple {
    color: #9c27b0;
}

.background-alerta{
    background-color: #ffd3b9;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker-wrapper {
    z-index: 0;
}

.rt-tbody::-webkit-scrollbar {
    display: none;
}

.rt-tbody {
    -ms-overflow-style: none;
}

.graficas-size {
    max-width: 3800px;
}

.icons8-sap { 
    display: inline-block;
    width: 48px;
    height: 48px;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjMTk3NmQyIiBkPSJNMCwxMnYyNGgyNWwyMy0yNEgweiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yNy41LDE4SDIzdjd2MnYwLjVMMTkuMiwxOGgtMy41bC0zLjA4OCw3Ljg4M2MtMC40MDUtMS43NTItMi4yNTktMi40MTctMy40MTItMi42ODNDNi4yLDIyLjQsNiwyMiw2LDIxLjRjMC0xLjUsNC0wLjgsNS0wLjRjMC4zLTAuOCwwLjktMi4zLDAuOS0yLjNTMTAuOCwxOCw3LjQsMThDNCwxOCwzLDIwLjEsMywyMS40YzAsMS4zLDAuNywyLjgsMi42LDMuNGMyLDAuNiwzLjUsMC43LDMuNSwxLjdzLTMuNCwxLjItNS4zLDBMMywyOS4xYzAsMCwxLjEsMC45LDQuNCwwLjljMS43ODEsMCwzLjE2Ny0wLjUwMyw0LjA4LTEuMjI2TDExLDMwaDMuNWwwLjYtMS44YzAuNywwLjMsMS41LDAuNSwyLjQsMC41czEuNy0wLjIsMi40LTAuNWwwLjYsMS44SDIzaDFoMnYtM2gxLjVjMi41LDAsNC41LTIsNC41LTQuNVMzMCwxOCwyNy41LDE4eiBNMTcuNSwyNi4xYy0wLjYsMC0xLjEtMC4xLTEuNS0wLjRsMS41LTQuM2wxLjUsNC4zQzE4LjYsMjYsMTguMSwyNi4xLDE3LjUsMjYuMXogTTI3LjUsMjRIMjZ2LTNoMS41YzAuOCwwLDEuNSwwLjcsMS41LDEuNVMyOC4zLDI0LDI3LjUsMjR6Ij48L3BhdGg+PC9zdmc+') 50% 50% no-repeat;
    background-size: 100%; 
}

.calendar-container {
    margin-top: 30%;
    width: 55%;
    margin: auto;
    position: relative;
    top: -200px;
    padding-top: 5px;
}

.weekdays-container {
    
    margin: auto;
    justify-content: space-around;    
    display: grid;
    font-size: 20px;
    grid-template-columns: repeat(7, 1fr);
    background: rgb(235, 232, 232);
}

.weekdays-container .week-day {
    color: #0b0b0b;
    text-align: center;
    font-weight: bold;    
}

.calendar {    
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: auto;
    align-items: flex-start;
    justify-content: flex-start;    
}

.calendar .week {
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    background: white
}

.calendar .week .date {
    border-radius: 10px;
    width: 30px;
    height: 100px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

button-c {
    background: rgb(255, 255, 255);
    border: none
}

.dp-container span {
    font-size: 20px;
}

.dp-container svg {
    border-radius: 100px;
    background: #1c1818;
    padding: 10px 10px;
    margin: 10px;
}

.today {
    background-color: #6ccffd;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    filter: drop-shadow(2px 2px 2px #2229f1);
}